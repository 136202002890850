import { initializeApp } from 'firebase/app'
import { GoogleAuthProvider, getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_APIKEY || 'mock_key',
  authDomain: process.env.NEXT_PUBLIC_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_PROJECTID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGINGSENDERID,
  appId: process.env.NEXT_PUBLIC_APPID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENTID,
}

const firebaseApp = initializeApp(firebaseConfig)

const auth = getAuth(firebaseApp) // share single auth across other services - storage, firestore etc
const storage = getStorage(firebaseApp) // storage is for media - images, files etc
const db = getFirestore(firebaseApp) // collections - events, users, orders
const provider = new GoogleAuthProvider()

export { db, storage, auth, provider, firebaseApp as admin }
